import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import {
  seo,
  intro,
  main,
  servicesSections,
  faq,
  blog,
  sections,
  features,
} from '../../content/transport-lotniczy'
import { useLangContext } from '../../context/lang.context'
import { useFormContext } from '../../context/form.context'
import Intro from '../../components/Intro'
import Button from '../../components/Button'
import { s, sButtonBack } from '../../style'
import LinkTile from '../../components/LinkTile'
import Main from '../../components/Main'
import ImageSection from '../../components/ImageSection'
import Features from '../../components/Features'
import Collapses from '../../components/Collapses'
import BannerContact from '../../sections/banners/BannerContact'
import FormSectionQuote from '../../sections/forms/FormSectionQuote'

const TransportLotniczy = () => {
  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()

  const { image, services, ...query } = useStaticQuery(graphql`
    {
      image: file(
        absolutePath: { regex: "/images/intros/transport-lotniczy.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      image1: file(
        absolutePath: { regex: "/images/services/air/image1.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      image2: file(
        absolutePath: { regex: "/images/services/air/image2.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      services: allContentfulService(sort: { fields: order }) {
        nodes {
          title
          type {
            name
          }
          lang {
            slug
          }
          slug
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              quality: 100
              placeholder: BLURRED
              formats: [JPG]
            )
          }
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Dowiedz się więcej',
      en: 'Learn more',
      ua: 'ДІЗНАТИСЯ БІЛЬШЕ ',
    },
    action: 'SCROLL',
  }

  const buttonSecond = {
    text: {
      pl: 'Wycena transportu',
      en: 'Transport quote',
      ua: 'ЦІНА ТРАНСПОРТУВАННЯ',
    },
    action: () => setFormOpen(true),
  }

  const buttonBack = {
    text: {
      pl: 'Powrót do transport',
      en: 'Back to transport',
      ua: 'ПОВЕРНЕННЯ ДО ТРАНСПОРТУ',
    },
    link: '/transport/',
  }

  const links = services.nodes
    .filter((node) => node.lang.slug === lang)
    .filter((node) => node.type.name === 'air')
    .map(({ slug, title, image }) => ({
      slug: `/transport/air/${slug}/`,
      title,
      image,
      subtitle: {
        pl: 'Transport Lotniczy',
        en: 'Air Transport',
        ua: 'Повітряний транспорт',
      },
    }))

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button, buttonSecond }}
        image={image.childImageSharp}
      />
      <Main h={1} title={main.title[lang]} article={main.body[lang]}></Main>
      <Main article={blog[lang]}>
        {sections?.map(({ title, desc }, id) => (
          <ImageSection
            h={2}
            s={2}
            key={id}
            title={title[lang]}
            desc={desc?.[lang]}
            image={query[`image${id + 1}`]?.childImageSharp}
          />
        ))}
      </Main>
      <Main h={2} title={features.title[lang]}>
        <Features data={features.features[lang]} gridRepeat={2} />
      </Main>
      <Main
        h={2}
        s={1}
        title={faq[lang].title}
        subtitle='FAQ'
        extraCssTitle={{
          [s.lg]: { maxWidth: '50%' },
        }}
      >
        <Collapses data={faq[lang].questions} fullWidth noGaps light />
      </Main>
      <Main h={2} s={1} title={servicesSections.title[lang]}>
        <div css={sLinkTiles}>
          {links.map((item, id) => (
            <LinkTile key={id} data={item} />
          ))}
        </div>
        <Button link={buttonBack.link}>{buttonBack.text[lang]}</Button>
      </Main>
      <BannerContact />
      <FormSectionQuote />
      <div css={sButtonBack}>
        <Button link={buttonBack.link}>{buttonBack.text[lang]}</Button>
      </div>
    </Layout>
  )
}

const sLinkTiles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '4rem',
  margin: '4rem 0 4rem',
  [s.sm_down]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: '2rem',
    margin: '2rem 0 2rem',
  },
}

export default TransportLotniczy
