export const seo = {
  url: 'transport/air',
  title: {
    pl: 'Transport Lotniczy',
    en: 'Air Transport',
    ua: 'Повітряний транспорт',
  },
  desc: {
    pl: `Gdy czas gra ważną rolę, zalecamy wykorzystanie frachtu lotniczego. Zawsze dbamy o to, aby towar był dostarczany ekspresowo, ale jednocześnie bezpiecznie.`,
    en: `When time is important, we recommend the use of air freight. In our company, we make sure that the goods are delivered expressly, but at the same time safely.`,
    ua: `Коли час відіграє важливу роль, ми рекомендуємо використовувати авіаперевезення. Ми завжди дбаємо про те, щоб товари доставлялися терміново.`,
  },
  keywords: [
    'omida',
    'sea and air',
    'sea & air',
    'spedycja',
    'transport lotniczy',
  ],
}

export const intro = {
  title: {
    pl: 'Transport Lotniczy',
    en: 'Air Transport',
    ua: 'Повітряний транспорт',
  },
  desc: {
    pl: `Gdy czas gra ważną rolę, zalecamy wykorzystanie frachtu lotniczego. Zawsze dbamy o to, aby towar został dostarczony ekspresowo.`,
    en: `When time is important, we recommend the use of air freight. In our company, we make sure that the goods are delivered expressly.`,
    ua: `Коли час відіграє важливу роль, ми рекомендуємо використовувати авіаперевезення. Ми завжди дбаємо про те, щоб товари доставлялися терміново.`,
  },
}

export const main = {
  title: {
    pl: 'Transport drogą powietrzną. <br/>Wybierz jedną spośród wielu usług transportu lotniczego',
    en: 'Air Transport. <br/>Choose from a wide range of air transport services',
    ua: 'Транспорт повітрям. <br/>Вибирайте одну з різноманітних авіатранспортних послуг',
  },
  body: {
    pl: `
    <p>
    Polecamy wykorzystanie transportu lotniczego <strong>dla ładunków o wysokiej wartości</strong>, <strong>krótkim czasie dostawy</strong> lub <strong>wrażliwych na czas</strong> przewozu. W Omida Sea And Air S.A. dbamy o bezpieczny i szybki transport Twoich przesyłek.
    </p>`,
    en: `
    <p>
    We recommend using air transport for <strong>high-value</strong>, <strong>fast-delivery</strong> or <strong>time-sensitive</strong> cargo. At Omida Sea And Air S.A. we make sure that the parcels are delivered expressly and safety.
    </p>
    `,
    ua: `
    <p>
    Ми рекомендуємо використовувати повітряний транспорт для перевезення вантажів високої вартості , короткочасних або термінових. Omida Sea And Air S.A. дбає про безпечне та швидке транспортування ваших відправлень.
    </p>
    `,
  },
}

export const servicesSections = {
  title: {
    pl: 'Więcej o transporcie lotniczym',
    en: 'More about air transport',
    ua: 'БІЛЬШЕ ПРО ПОВІТРЯНИЙ ТРАНСПОРТ',
  },
}

export const blog = {
  pl: `
  <div>
  <h2>Kiedy wybieramy transport lotniczy?</h2>
  <ul>
  <li>krótki termin realizacji dostawy</li>
  <li>przewóz wartościowych towarów</li>
  <li>transport towarów szybko psujących się</li>
  <li>import towarów wymagających temperatury kontrolowanej</li>
  </ul>
  <p>Wyróżniamy trzy rodzaje transportu lotniczego: <strong>ekonomiczny</strong>, <strong>przyspieszony</strong> oraz <strong>priorytetowy</strong>, który polecamy w momencie szybkiego terminu dostawy.</p>
  <h2>Jak przygotować się do transportu lotniczego?</h2>
  <p>Do przygotowania kompleksowej oferty w transporcie lotniczym w przypadku ładunków neutralnych potrzebujemy informacji o: ilości opakowań, wadze, wymiarach oraz czasie dostawy. Przy ładunkach niebezpiecznych DGR wymagamy dodatkowo dokumentu MSDS, a przy towarach z temperaturą kontrolowaną zapewniamy odpowiedni zakres temperatur.</p>
  <p>Do najczęściej przewożonych towarów należą części zamienne z branż automotive, opony, odzież oraz towary szybko psujące się. Zachęcamy do łączenia transportu z sektorem e‑commerce, w którym zapewniamy stały zakres dostaw pomiędzy producentami, firmami oraz finalnymi odbiorcami produktu.</p>
  <h2>Jak przebiega proces transportu lotniczego?</h2>
  <p>Objaśniamy poszczególne etapy i miejsca dla importowanych towarów z Azji do Europy. Dowiedz się, jak przebiega odprawa eksportowa, kontrola bezpieczeństwa, czy wydanie towaru na płytę lotniska pod konkretny samolot. Mamy dostępne chłodnie i mroźnie dla towarów wymagających niskich i bardzo niskich temperatur.</p>
  <p><strong>Transport lotniczy wybieramy zawsze wtedy, kiedy mamy mało czasu na dostarczenie towaru do finalnego odbiorcy lub towar jest szybko psujący się</strong>. Jako Omida Sea And Air S.A. transportujemy przesyłki w każde miejsce na świecie i odbieramy przesyłki z każdego miejsca na świecie. Rozbudowana sieć naszych agentów pozwala na dostarczanie przesyłek od drzwi do drzwi.</p>
  </div>
  `,
  en: `
  <div>
  <h2>When we choose Air Transport?</h2>
  <ul>
  <li>short delivery time</li>
  <li>transportation of valuable goods</li>
  <li>transport of perishable goods</li>
  <li>import of goods requiring controlled temperature</li>
  </ul>
  <p>We distinguish three types of air transport: <strong>economic</strong>, <strong>express</strong> and <strong>priority</strong> which we recommend at the time of fast delivery.</p>
  <h2>How to prepare for air transport?</h2>
  <p>To prepare a comprehensive offer in air transport in the case of neutral loads, we need to know: the number of packages, weight, dimensions and delivery time. In the case of DGR dangerous goods, we additionally require an MSDS document, and in the case of goods with controlled temperature, we provide an appropriate temperature range.</p>
  <p>The most frequently transported goods include spare parts from the automotive industry, tires, clothing and perishable goods. We encourage you to combine transport with the e‑commerce sector, in which we provide a constant range of supplies between producers, companies and end recipients of the product.</p>
  <h2>How is the air transport process going?</h2>
  <p>We explain the various stages and places for imported goods from Asia to Europe. Find out how the export clearance, security control, and release of goods to the apron for a specific plane are carried out. We have cold stores and freezers available for goods that require low and very low temperatures.</p>
  <p>We always choose air transport when we have little time to deliver the goods to the final recipient or the goods are perishable. As Omida Sea And Air S.A. we transport parcels to any place in the world and collect parcels from anywhere in the world. An extensive network of our agents allows us to deliver shipments from door to door.</p>
  </div>
  `,
}

export const features = {
  title: {
    pl: 'Dlaczego warto wybrać transport lotniczy?',
    en: 'Why is it worth choosing air transport?',
    ua: 'Чому варто вибрати авіатранспорт?',
  },
  features: {
    pl: [
      {
        desc: 'Oferujemy obsługę transportu w relacji door-to-door, door-airport, airport-door, airport-airport.',
        title: 'Nowoczesne rozwiązania',
      },
      {
        desc: 'Zapewniamy kompleksową obsługę w portach lotniczych na całym świecie.',
        title: 'Kompleksowa obsługa',
      },
      {
        desc: 'Współpracujemy ze wszystkimi największymi liniami lotniczymi.',
        title: 'Obszerna współpraca',
      },
      {
        desc: 'W ramach transportu lotniczego oferujemy sprawną i szybką obsługę celną ładunków w eksporcie, imporcie oraz tranzycie towarów.',
        title: 'Sprawność i szybkość działania',
      },
    ],
    en: [
      {
        desc: 'We offer transport services on a door-to-door, door-airport, airport-door, or airport-airport basis.',
        title: 'Modern solutions',
      },
      {
        desc: 'We provide comprehensive service in airports around the world.',
        title: 'Full service',
      },
      {
        desc: 'We work with all major airlines.',
        title: 'Extensive cooperation',
      },
      {
        desc: 'As part of air transport, we offer efficient and fast customs service for export, import and transit of goods.',
        title: 'Efficiency and speed of operation',
      },
    ],
    ua: [
      {
        desc: 'Ми пропонуємо транспортні послуги door-to-door, door-airport, airport-door, airport-airport.',
        title: 'СУЧАСНІ РІШЕННЯ',
      },
      {
        desc: 'Ми надаємо комплексні послуги в аеропортах по всьому світу.',
        title: 'ПОВНИЙ КОМПЛЕКС ПОСЛУГ',
      },
      {
        desc: 'Ми працюємо з усіма великими авіакомпаніями.',
        title: 'ВСЕБІЧНА СПІВПРАЦЯ',
      },
      {
        desc: 'У рамках авіаперевезень ми пропонуємо ефективне та швидке митне обслуговування вантажів при експорті, імпорті та транзиті товарів.',
        title: 'ЕФЕКТИВНІСТЬ ТА ШВИДКІСТЬ РОБОТИ',
      },
    ],
  },
}

export const faq = {
  pl: {
    title: 'Najczęściej zadawane pytania & odpowiedzi',
    questions: [
      {
        a: 'Standardowymi dokumentami wymaganymi przy obsłudze transportu lotniczego są: faktura handlowa za towar będący przedmiotem wysyłki oraz specyfikacja (packing list). Zawiera ona podstawowe dane nadawcy i odbiorcy oraz informacje o ilości, wadze, wymiarach i rodzaju opakowań. Czasem potrzebne są dodatkowe dokumenty, na przykład w przypadku transportu towarów niebezpiecznych lub podlegających specyficznym regulacjom. Ponadto w niektórych państwach odbiorcy wymagają świadectwa pochodzenia towaru lub innych, niestandardowych dokumentów. Zachęcamy do sprawdzania wymogów przed wysyłką.',
        q: 'Jakie dokumenty są potrzebne do zlecenia transportu lotniczego?',
      },
      {
        a: 'Przyjmuje się, że sam czas transportu lotniczego w relacji lotnisko-lotnisko dla przesyłek międzykontynentalnych wynosi około 1 dnia. W przypadku połączeń bezpośrednich (z Polski są one dostępne jedynie do kilku lotnisk poza Europą), około 2 dni w przypadku serwisów ekspresowych, około 3-4 dni w przypadku serwisów standardowych i około 5-7 dni w przypadku serwisów ekonomicznych. Podane orientacyjne czasy transportu nie obejmują czasu potrzebnego na odbiór przesyłki, odprawę eksportową, kontrolę bezpieczeństwa. Nie wlicza się w niego, również oczekiwanie na załadunek w kraju eksportera, oraz na operacje lotniskowe, odprawa importowa i dostawa do drzwi w kraju importera.',
        q: 'Ile czasu trwa transport lotniczy?',
      },
      {
        a: 'Maksymalne wymiary i waga towaru, które mogą być zaakceptowane przez linię lotniczą, zależą od typów samolotów wykorzystywanych na danej trasie. Większość połączeń pomiędzy lotniskami na świecie obsługiwana jest przez samoloty szerokokadłubowe. Do głównych ograniczeń takich statków powietrznych należą wymiary palety lotniczej oraz wysokość luku bagażowego. Przyjmuje się, że towar mieszczący się na standardową paletę lotniczą o wymiarach 317 x 223 cm i o wysokości 75 cm na trasach europejskich wynosi, 160 cm na trasach transatlantyckich wynosi 160 cm oraz 220 cm w samolotach cargo. W wielu przypadkach sprawdzamy więc z liniami lotniczymi, czy przyjmą dany towar na danej trasie.',
        q: 'Jakie są maksymalne wymiary i waga ładunku, jakie można przetransportować drogą lotniczą?',
      },
      {
        a: 'Tak. Przewóz towaru w temperaturze kontrolowanej może się odbywać na wiele różnych sposobów - w tak zwanym serwisie pasywnym. Jest to usługa, podczas której linia lotnicza zobowiązuje się do transportu towaru w temperaturze od +15 do +25 ℃, a także do przechowania towaru na terminalach lotniczych podczas przeładunków w takiej temperaturze, w lotniczym kontenerze chłodniczym, zapewniającym temperaturę od +2 do +8 ℃, przy pomocy izotermicznych opakowań, czasem wypełnionych suchym lodem, zapewniających wymaganą temperaturę w określonym przedziale czasowym. Odpowiedni dobór serwisu zależy od rodzaju towaru i wymaganej temperatury, trasy przewozu i dostępnych na niej serwisów oraz od możliwości przygotowania towaru do transportu przez eksportera. ',
        q: 'Czy drogą lotniczą można przewieźć ładunki w temperaturze kontrolowanej?',
      },
    ],
  },
  en: {
    title: 'Frequently asked questions & answers',
    questions: [
      {
        a: 'The standard documents required for air transport are: a commercial invoice for the goods being shipped and a packing list containing basic details of the sender and recipient as well as information on the quantity, weight, dimensions and type of packaging. Sometimes additional documents are needed, for example in the case of transporting dangerous goods or goods subject to specific regulations. In addition, in some countries, recipients require a certificate of origin or other non-standard documents, so it is worth checking before shipment if the recipient has any specific requirements in this respect.',
        q: 'What documents are needed to order air transport?',
      },
      {
        a: "For illustration, airport-to-airport time alone for intercontinental shipments is about 1 day for direct flights (from Poland this is available only to a few airports outside Europe), about 2 days for express services, about 3-4 days for standard services and around 5-7 days for economy services. The above mentioned approximate transport times do not include the time needed to receive the shipment, export clearance, security control and waiting for loading in the exporter's country, as well as for airport operations, import clearance and delivery to door in the importer's country.",
        q: 'How long does air transport take?',
      },
      {
        a: "The maximum dimensions and weight of cargo that can be accepted by the airline depend on the types of aircraft used on the route. Most flights between the world's major airports are served by wide-bodied aircraft, the main limitation of which is the size of the air pallet and the height of the cargo hold. Most often it can be assumed that the cargo on a standard air pallet with dimensions of 317 x 223 cm and a height of 75 cm on European routes, is 160 cm on transatlantic routes and 220 cm in cargo planes. Therefore, in many cases we check with the airlines whether they will accept the goods on a given route.",
        q: 'What are the maximum dimensions and weight of cargo that can be transported by air?',
      },
      {
        a: 'Yes. The carriage of goods in controlled temperature can be done in multiple ways. So-called passive service, which is a service where the airline commits itself to transport goods at a temperature between +15 and +25℃. It also stores the goods at airport terminals during reloading within this temperature range. In an airborne refrigerated container, guaranteeing temperatures from +2 to +8℃. By means of isothermal packages, sometimes filled with dry ice, providing the required temperature over a certain period of time. Appropriate selection of service depends on the type of goods and the required temperature, the transport route and the services available on it, as well as the possibility of preparing goods for transport by the exporter.',
        q: 'Is it possible to transport cargo by air at a controlled temperature?',
      },
    ],
  },
  ua: {
    title: 'Часті запитання та відповіді',
    questions: [
      {
        a: 'Стандартними документами, необхідними для повітряних перевезень, є: комерційний рахунок-фактура на товари, що перевозяться, та пакувальний лист, що містить основні відомості про відправника та одержувача, а також інформацію про кількість, вагу, розміри та тип упаковки. Іноді потрібні додаткові документи, наприклад, у разі перевезення небезпечних вантажів або товарів, які підпадають під дію спеціальних правил. Крім того, у деяких країнах одержувачі вимагають сертифікат походження або інші нестандартні документи, тому перед відправленням варто перевірити, чи є у одержувача якісь особливі вимоги щодо цього.',
        q: 'Які документи потрібні для замовлення авіаперевезення?',
      },
      {
        a: "Для прикладу, лише час між аеропортом для міжконтинентальних відправлень становить приблизно 1 день для прямих рейсів (з Польщі це доступно лише до кількох аеропортів за межами Європи), приблизно 2 дні для експрес-послуг, приблизно 3-4 дні для стандартних послуг і близько 5-7 днів для економ-послуг. Вищезазначені приблизні терміни транспортування не включають час, необхідний для отримання вантажу, експортного очищення, контролю безпеки та очікування завантаження в країні експортера, а також для операцій в аеропорту, імпортного очищення та доставки до дверей у країні імпортера.",
        q: 'Скільки часу займає повітряний транспорт?',
      },
      {
        a: "Максимальні габарити та вага вантажу, який може прийняти авіакомпанія, залежать від типів літаків, що використовуються на маршруті. Більшість рейсів між великими аеропортами світу обслуговуються широкофюзеляжними літаками, основним обмеженням яких є розмір повітряного піддона та висота вантажного відсіку. Найчастіше можна вважати, що вантаж на стандартному аеропіддоні розміром 317 х 223 см і висотою 75 см на європейських маршрутах становить 160 см на трансатлантичних маршрутах і 220 см на вантажних літаках. Тому в багатьох випадках ми перевіряємо в авіакомпаній, чи приймуть вони вантажі за даним маршрутом.",
        q: 'Які максимальні розміри та вага вантажу можна перевозити повітряним транспортом?',
      },
      {
        a: 'Так. Перевезення вантажів з контрольованою температурою може здійснюватися різними способами. Так звана пасивна послуга, яка є послугою, коли авіакомпанія зобов’язується перевозити вантажі за температури від +15 до +25 ℃. Він також зберігає товари в терміналах аеропорту під час перевантаження в цьому діапазоні температур. У бортовому рефрижераторному контейнері, що гарантує температуру від +2 до +8℃. За допомогою ізотермічних пакетів, іноді заповнених сухим льодом, забезпечують необхідну температуру протягом певного часу. Відповідний вибір послуги залежить від типу товару та необхідної температури, транспортного маршруту та доступних на ньому послуг, а також від можливості підготовки товару до транспортування експортером.',
        q: 'Чи можна транспортувати вантаж повітрям при контрольованій температурі?',
      },
    ],
  },
}

export const sections = [
  {
    title: {
      pl: 'Rozwiązania intermodalne',
      en: 'Intermodal solutions',
      ua: 'Інтермодальні рішення',
    },
    desc: {
      pl: `Świadczymy <strong>rozwiązania intermodalne łączące szybkość transportu lotniczego z atrakcyjną ceną frachtu oceanicznego.</strong> Przesyłki są nadawane w portach morskich w Szanghaju, Hongkongu, Taiwanie lub Shenzhen do Dubaju (Zjednoczone Emiraty Arabskie), a następnie transportowane samolotem do Frankfurtu nad Menem.`,
      en: `We provide intermodal solutions combining the speed of air transport with attractive ocean freight prices. Consignments are shipped from Shanghai, Hong Kong, Taiwan or Shenzhen seaports to Dubai (United Arab Emirates) and then transported by plane to Frankfurt am Main.`,
      ua: `Ми пропонуємо інтермодальні рішення, що поєднують швидкість повітряного транспорту з привабливою ціною морських перевезень. Відправлення відправляються в морських портах Шанхаю, Гонконгу, Тайваню або Шеньчженя до Дубая (Об’єднані Арабські Емірати), а потім транспортуються літаком до Франкфурта-на-Майні.`,
    },
  },
  {
    title: {
      pl: 'Najszerszy zakres ubezpieczenia cargo',
      en: 'Widest range of cargo insurance',
      ua: 'Найширший спектр страхування вантажів',
    },
    desc: {
      pl: `Oferujemy <strong>najszerszy zakres ubezpieczenia cargo</strong>, zapewniający ochronę ładunku na całej trasie przewozu, w tym również podczas załadunku, rozładunku i składowania.`,
      en: `We offer the widest range of cargo insurance, ensuring protection of cargo along the entire route of transport, including loading, unloading and storage.`,
      ua: `Ми пропонуємо найширший спектр страхування вантажів , забезпечуючи захист вантажу на всьому шляху транспортування, включаючи навантаження, розвантаження та зберігання.`,
    },
  },
]
